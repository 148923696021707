<template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="68px">
      <el-form-item class="el_form_item" label="运单编号">
        <el-input
          class="el_input"
          v-model="form.truck_tord_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="上游编号">
        <el-input
          class="el_input"
          v-model="form.out_trade_num"
          placeholder="运单上游编号"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item class="el_form_item" label="车主手机">
        <el-input
          class="el_input"
          v-model="form.truck_owner_tel"
          placeholder="车主手机"
          clearable
        ></el-input>
      </el-form-item> -->
      <el-form-item class="el_form_item" label="司机手机">
        <el-input
          class="el_input"
          v-model="form.driver_tel"
          placeholder="司机手机"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item class="el_form_item" label="车牌号">
        <el-input
          class="el_input"
          v-model="form.truck_plate_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="货物名称">
        <el-input
          class="el_input"
          v-model="form.cargo_name"
          placeholder="货物名称搜索"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item class="el_form_item" label="货物性质">
        <el-select class="el_input" v-model="form.cargo_type" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option
            v-for="(item, index) in ser_para.cargo_type"
            :key="index"
            :label="item"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item> </el-form-item>
      <el-form-item class="el_form_item" label="装货地">
        <el-cascader
          class="el_input"
          v-model="form.case_cities"
          @change="ser_para_case_cities_change"
          placeholder="装货地省市区"
          :options="ser_para.cities"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item class="el_form_item" label="到货地">
        <el-cascader
          class="el_input"
          v-model="form.aim_cities"
          @change="ser_para_aim_cities_change"
          placeholder="到货地省市区"
          :options="ser_para.cities"
          filterable
        ></el-cascader>
      </el-form-item>
      <!-- <el-form-item class="el_form_item" label="支付状态">
        <el-select class="el_input" v-model="form.pay_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="支付完成" value="2"></el-option>
          <el-option label="失败/打回" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="开票状态">
        <el-select class="el_input" v-model="form.invoice_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="未申请" value="1"></el-option>
          <el-option label="申请中" value="2"></el-option>
          <el-option label="已开出" value="3"></el-option>
          <el-option label="已驳回" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="结算状态">
        <el-select class="el_input" v-model="form.settle_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="未申请" value="1"></el-option>
          <el-option label="待打款" value="2"></el-option>
          <el-option label="已打款" value="3"></el-option>
          <el-option label="结算失败" value="4"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item class="el_form_item elfrom_item" label="发起时间">
        <div class="block">
          <el-date-picker
            v-model="date_value"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item class="el_form_item elfrom_item"> </el-form-item>
      <el-form-item label-width="0" id="button">
        <el-button type="primary" @click="cargo_ser">查询</el-button>
        <el-button type="primary" @click="ser_para_clear">清空条件</el-button>
        <el-button type="primary" @click="pay_confirm()">批量审核</el-button>
        <el-button
          type="primary"
          @click="pay_confirm(setConfirm)"
          v-if="trans_status == 7"
          >批量开票</el-button
        >
        <el-button type="success" @click="data_exportFile"
          >导出运单数据</el-button
        >
      </el-form-item>
    </el-form>
    <!-- <template> -->
    <!-- <el-radio-group
        v-model="trans_status"
        style="margin-bottom: 30px"
        @change="handleClick()"
      >
        <el-radio-button label="1">全部</el-radio-button>
        <el-radio-button label="2">审核中</el-radio-button> -->
    <!-- <el-radio-button label="3">待接单</el-radio-button> -->
    <!-- <el-radio-button label="4">运输中</el-radio-button>
        <el-radio-button label="5">已到货</el-radio-button>
        <el-radio-button label="7">可开票</el-radio-button>
        <el-radio-button label="8">开票中</el-radio-button>
        <el-radio-button label="9">已开票</el-radio-button>
      </el-radio-group> -->
    <!-- <el-tabs v-model="activeName"  class="tabs" :stretch="true">
        <el-tab-pane label="全部" name="1"></el-tab-pane>
        <el-tab-pane label="审核中" name="2"></el-tab-pane>
        <el-tab-pane label="待接单" name="3"></el-tab-pane>
        <el-tab-pane label="运输中" name="4"></el-tab-pane>
        <el-tab-pane label="已到货" name="5"></el-tab-pane>
        <!-- <el-tab-pane label="已完结" name="6"></el-tab-pane> -->
    <!-- <el-tab-pane label="可开票" name="7" class="tabs_invoice"></el-tab-pane>
      </el-tabs> -->
    <!-- </template> -->
    <div class="total_info">
      <span>共:{{ list.total }}条</span>
      <span>总运费:{{ list.item.freight_total }}元</span>
      <!-- <span>支付审核中:{{ list.item.check_total }}元</span> -->
      <span>现金:{{ list.item.cash_total }}元</span>
      <span>油费:{{ list.item.fuel_total }}元</span>
    </div>
    <!-- 表格 -->
    <div class="tab_height" id="flex">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
        @selection-change="select_table"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column label="运单编号/上游单号" width="130px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              (编号) {{ scope.row.truck_tord_num }}
            </div>
            <div class="tab_line_item" v-if="list.data.group1_num == ''">
              (组别) {{ scope.row.group1_num }}
            </div>
            <div class="tab_line_item">
              (上游) {{ scope.row.out_trade_num }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="地址/费用信息" align="center">
          <template slot-scope="scope">
            <div
              class="tab_line_item tab_line"
              style="text-align: left; padding-left: 18px"
            >
              装：(省市区) {{ scope.row.case_prov }} /
              {{ scope.row.case_city }} /
              {{ scope.row.case_county }}
              (详&nbsp;&nbsp;&nbsp;细)
              {{ scope.row.case_addr ? scope.row.case_addr : "无" }}
            </div>
            <div
              class="tab_line_item tab_line"
              style="text-align: left; padding-left: 18px; margin-bottom: 10px"
            >
              卸：(省市区) {{ scope.row.aim_prov }} / {{ scope.row.aim_city }} /
              {{ scope.row.aim_county }}
              (详&nbsp;&nbsp;&nbsp;细)
              {{ scope.row.aim_addr ? scope.row.aim_addr : "无" }}
            </div>
            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  <div class="title">
                    <div>总运费:{{ scope.row.cost_info.freight_total }}</div>
                    <div>现金:{{ scope.row.cost_info.cash_total }}元</div>
                    <div>油费:{{ scope.row.cost_info.fuel_total }}元</div>
                    <div>已付现金:{{ scope.row.cost_info.cash_payed }}元</div>
                    <div>
                      已付油费:{{ scope.row.cost_info.fuel_cost_payed }}元
                    </div>
                  </div>
                </template>
                <div
                  class="oli_line_item"
                  style="text-align: left; margin-top: 10px"
                  v-if="scope.row.payed_info.cash_2 != 0"
                >
                  <div class="flex">
                    <div>燃油费 {{ scope.row.payed_info.cash_2 }} 元</div>
                    <div>审核中:{{ scope.row.payed_info.cash_paying_2 }}元</div>
                    <div>已支付:{{ scope.row.payed_info.cash_payed_2 }}元</div>
                    <div></div>
                    <div></div>
                    <!-- <div>
                      开票中:{{ scope.row.payed_info.cash_invoicing_2 }}元
                    </div>
                    <div>
                      已开票:{{ scope.row.payed_info.cash_invoiced_2 }}元
                    </div> -->
                  </div>
                </div>
                <div
                  class="oli_line_item"
                  style="text-align: left"
                  v-if="scope.row.payed_info.cash_11 != 0"
                >
                  <div class="flex">
                    <div>预付款 {{ scope.row.payed_info.cash_11 }}元</div>
                    <div>
                      审核中:{{ scope.row.payed_info.cash_paying_11 }}元
                    </div>
                    <div>已支付:{{ scope.row.payed_info.cash_payed_11 }}元</div>
                    <div>
                      结算中:{{ scope.row.payed_info.cash_settling_11 }}元
                    </div>
                    <div>
                      已结算:{{ scope.row.payed_info.cash_settled_11 }}元
                    </div>
                    <!-- <div>
                      开票中:{{ scope.row.payed_info.cash_invoicing_11 }}元
                    </div>
                    <div>
                      已开票:{{ scope.row.payed_info.cash_invoiced_11 }}元
                    </div> -->
                  </div>
                </div>
                <div
                  class="oli_line_item"
                  style="text-align: left"
                  v-if="scope.row.payed_info.cash_12 != 0"
                >
                  <div class="flex">
                    <div>到货付 {{ scope.row.payed_info.cash_12 }}元</div>
                    <div>
                      审核中:{{ scope.row.payed_info.cash_paying_12 }}元
                    </div>
                    <div>已支付:{{ scope.row.payed_info.cash_payed_12 }}元</div>
                    <div>
                      结算中:{{ scope.row.payed_info.cash_settling_12 }}元
                    </div>
                    <div>
                      已结算:{{ scope.row.payed_info.cash_settled_12 }}元
                    </div>
                    <!-- <div>
                      开票中:{{ scope.row.payed_info.cash_invoicing_12 }}元
                    </div>
                    <div>
                      已开票:{{ scope.row.payed_info.cash_invoiced_12 }}元
                    </div> -->
                  </div>
                </div>
                <div
                  class="oli_line_item"
                  style="text-align: left"
                  v-if="scope.row.payed_info.cash_13 != 0"
                >
                  <div class="flex">
                    <div>尾款付 {{ scope.row.payed_info.cash_13 }}元</div>
                    <div>
                      审核中:{{ scope.row.payed_info.cash_paying_13 }}元
                    </div>
                    <div>已支付:{{ scope.row.payed_info.cash_payed_13 }}元</div>
                    <div>
                      结算中:{{ scope.row.payed_info.cash_settling_13 }}元
                    </div>
                    <div>
                      已结算:{{ scope.row.payed_info.cash_settled_13 }}元
                    </div>
                    <!-- <div>
                      开票中:{{ scope.row.payed_info.cash_invoicing_13 }}元
                    </div>
                    <div>
                      已开票:{{ scope.row.payed_info.cash_invoiced_13 }}元
                    </div> -->
                  </div>
                </div>
                <div
                  class="oli_line_item"
                  style="text-align: left"
                  v-if="scope.row.payed_info.cash_14 != 0"
                >
                  <div class="flex">
                    <div>账单付 {{ scope.row.payed_info.cash_14 }}元</div>
                    <div>
                      审核中:{{ scope.row.payed_info.cash_paying_14 }}元
                    </div>
                    <div>已支付:{{ scope.row.payed_info.cash_payed_14 }}元</div>
                    <div>
                      结算中:{{ scope.row.payed_info.cash_settling_14 }}元
                    </div>
                    <div>
                      已结算:{{ scope.row.payed_info.cash_settled_14 }}元
                    </div>
                    <!-- <div>
                      开票中:{{ scope.row.payed_info.cash_invoicing_14 }}元
                    </div>
                    <div>
                      已开票:{{ scope.row.payed_info.cash_invoiced_14 }}元
                    </div> -->
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </template>
        </el-table-column>
        <!-- <el-table-column label="卸货地信息" align="center">
          <template slot-scope="scope">

          </template>
        </el-table-column> -->
        <el-table-column label="名称/重量/体积" width="140px">
          <template slot-scope="scope">
            <div class="tab_line_item">名称: {{ scope.row.cargo_name }}</div>
            <div class="tab_line_item">
              重量:
              {{
                scope.row.cargo_weight == 0
                  ? "未设置"
                  : scope.row.cargo_weight + "吨"
              }}
            </div>
            <div class="tab_line_item">
              体积:
              {{
                scope.row.cargo_volume == 0
                  ? "未设置"
                  : scope.row.cargo_volume + "方"
              }}
            </div>
            <div class="tab_line_item">距离: {{ scope.row.distance }} 公里</div>
          </template>
        </el-table-column>
        <el-table-column label="司机/货车信息" width="180px">
          <template slot-scope="scope">
            <!-- <div class="tab_line_item">
              车主: {{ scope.row.truck_owner_info.name }}/{{
                scope.row.truck_owner_info.tel
              }}
            </div> -->
            <div class="tab_line_item">
              司机: {{ scope.row.driver_info.name }}/{{
                scope.row.driver_info.tel
              }}
            </div>
            <div class="tab_line_item">
              车牌: {{ scope.row.truck_info.truck_plate_num }}
            </div>
            <div
              class="tab_line_item"
              v-if="scope.row.truck_info.truck_type_name_text"
            >
              类型: {{ scope.row.truck_info.truck_type_name_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="运单状态/时间信息" width="172px">
          <template slot-scope="scope">
            <div class="tab_line_item">状态: {{ scope.row.status_text }}</div>
            <!-- <div class="tab_line_item">
              添加: {{ scope.row.creat_time_text }}
            </div> -->
            <div class="tab_line_item">
              发车: {{ scope.row.start_time_text }}
            </div>
            <div class="tab_line_item">
              到货: {{ scope.row.arrived_time_text }}
            </div>
            <div class="tab_line_item" v-if="scope.row.invoice_status == 1">
              开票状态：未申请
            </div>
            <div class="tab_line_item" v-if="scope.row.invoice_status == 2">
              开票状态：开票中{{ scope.row.cost_info.invoiced_total }}元
            </div>
            <div class="tab_line_item" v-if="scope.row.invoice_status == 3">
              开票状态：已开出{{ scope.row.cost_info.invoiced_total }}元
            </div>
          </template>
        </el-table-column>

        <!-- 操作行 -->
        <el-table-column label="操作" width="71" align="center">
          <template slot-scope="scope">
            <!-- 发票详情弹窗 -->
            <!-- <el-popover
              placement="top"
              title="申请开票信息"
              width="800"
              trigger="click"
            >
              <el-descriptions>
                <el-descriptions-item label="上游编号">{{
                  details_invoice.out_trade_num
                }}</el-descriptions-item>
                <el-descriptions-item label="总运费">{{
                  details_invoice.freight_total
                }}</el-descriptions-item>
                <el-descriptions-item label="总手续费">{{
                  details_invoice.service_charge_total
                }}</el-descriptions-item>
                <el-descriptions-item label="现金已付">
                  {{ details_invoice.cash_payed }}
                </el-descriptions-item>
                <el-descriptions-item label="油费已付">{{
                  details_invoice.fuel_payed
                }}</el-descriptions-item>
                <el-descriptions-item label="开票金额">{{
                  details_invoice.invoice_total
                }}</el-descriptions-item>
                <el-descriptions-item label="费用是否已全部支付">{{
                  details_invoice.is_all_payed == 1 ? "是" : "否"
                }}</el-descriptions-item>
                <el-descriptions-item label="是否可申请开票">{{
                  details_invoice.is_ready_invoice == 1 ? "是" : "否"
                }}</el-descriptions-item>
                <el-descriptions-item label="当前开票状态 ">{{
                  details_invoice.invoice_status
                }}</el-descriptions-item>
              </el-descriptions>
              <el-button
                v-if="
                  details_invoice.invoice_status === '未申请' ||
                  (details_invoice.invoice_status === '已撤回' &&
                    details_invoice.is_ready_invoice != 1)
                "
                class="btn_left btn_button"
                @click="invoice_apply(scope.row, scope.row.group_num)"
                size="mini"
                type="primary"
                >申请开票</el-button
              >
              <el-button
                v-if="details_invoice.invoice_status == '开票中'"
                class="btn_left"
                @click="invoice_cancel_show = true"
                size="mini"
                type="danger"
                >撤回</el-button
              >
              <!-- 发票撤销弹出框 -->
            <!-- <el-dialog
                title="点击确认撤销发票申请"
                :visible.sync="invoice_cancel_show"
                width="30%"
                top="40vh"
                :modal="false"
              >
                <span
                  >将退回手续费{{
                    details_invoice.service_charge_total
                  }}
                  元</span
                >
                <span slot="footer" class="dialog-footer">
                  <el-button @click="invoice_cancel_show = false"
                    >取 消</el-button
                  >
                  <el-button
                    type="primary"
                    @click="invoice_cancel(scope.row, scope.row.group_num)"
                    >确 定</el-button
                  >
                </span>
              </el-dialog>
              <!-- 开票密码弹出框 -->
            <!-- <el-button
                v-if="scope.row.status == 3"
                class="btn_left"
                size="mini"
                type="text"
                slot="reference"
                @click="
                  invoice_data(scope.row.truck_tord_num, scope.row.group_num)
                "
                >开票详情</el-button
              >
            </el-popover> -->
            <!-- <el-button
              class="btn_left"
              v-if="scope.row.status == 1"
              @click="tord_start(scope.row)"
              size="mini"
              type="text"
              >确定发车</el-button
            > -->
            <!-- <el-button
              class="btn_left"
              v-if="scope.row.status == 2"
              @click="tord_arrived(scope.row)"
              size="mini"
              type="text"
              >确定到货</el-button
            >
            <el-button
              class="btn_left"
              @click="voucher_show(scope.row.truck_tord_num)"
              size="mini"
              type="text"
              >运单凭证</el-button
            >
            <el-button
              class="btn_left"
              v-if="scope.row.status == 2 || scope.row.status == 3"
              @click="single_confirm(scope.row)"
              size="mini"
              type="text"
              >支付运费</el-button
            >
            <!-- <el-button class="btn_left" @click="tord_cancel(scope.row)" size="mini" type="text">取消</el-button> -->
            <!-- <el-button
              class="btn_left"
              @click="tord_payed_list(scope.row)"
              size="mini"
              type="text"
              >支付记录</el-button
            > -->
            <el-button
              class="btn_left"
              @click="etc_start(scope.row)"
              size="mini"
              type="text"
              >etc发车</el-button
            >
            <el-button
              class="btn_left"
              @click="etc_end(scope.row)"
              size="mini"
              type="text"
              >etc到货</el-button
            >
            <el-button
              class="btn_left"
              @click="etc_invoice(scope.row)"
              size="mini"
              type="text"
              >etc开票</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>

    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 50, 100, 500]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import xlsx from "xlsx";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      //搜索条件参数
      ser_para: {
        cargo_type: [], //货物类型
        cities: regionData, //省市区数据
      },
      date_value: "", //时间选择
      //搜索条件
      form: {
        truck_cargo_num: "", //货源编号
        out_trade_num: "", //上游编号
        truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        cargo_type: "", //货物性质
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        pay_status: "", //支付状态
        invoice_status: "", //开票状态
        settle_status: "", //结算状态

        start_time_start: "", // 开始时间
        arrived_time_end: "", // 结束

        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
      },
      group_nums: [],
      check_state: 1,
      setConfirm: {
        is_show: 0,
        ids: [],
        ra_nums: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      exportFile_ids: [], //导出得ids
      trans_status: "1", //切换 1全部/2 待接单/3运输中/4已到货/5已完结/6可开票
      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
        item: {},
      },

      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },

      //凭证相关
      voucher: {
        is_show: 0,
        truck_tord_num: "",
      },
      //合同相关
      contract: {
        tord_info: {},
        is_show: 0,
      },
      alone: {
        is_show: 0,
        ids: [],
      },
    };
  },
  created() {
    //获取可用搜索参数
    //this.get_ser_para();

    //读取货源
    this.get_page_data();
  },
  methods: {
   

    //etc到货
    etc_end(item) {
      var truck_tord_nums = [];
      truck_tord_nums.push(item.truck_tord_num);
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "etc_end",
          truck_tord_nums,
        },
        callback: (data) => {
          console.log(data);
        },
      });
    },
    //etc发车
    etc_start(item) {
      var truck_tord_nums = [];
      truck_tord_nums.push(item.truck_tord_num);
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "etc_start",
          truck_tord_nums,
        },
        callback: (data) => {
          console.log(data);
        },
      });
    },
    //etc自动开票
    etc_invoice(item) {
      var truck_tord_nums = [];
      truck_tord_nums.push(item.truck_tord_num);
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "etc_invoice_apply",
          truck_tord_nums,
        },
        callback: (data) => {
          console.log(data);
        },
      });
    },
    //勾选提示框
    data_exportFile() {
      if (this.setConfirm.ids.length == 0) {
        this.$my.other.confirm({
          content: "当前未勾选数据,是否全部导出？",
          confirm: () => {
            this.exportFile(this.exportFile_ids);
          },
        });
      } else {
        this.exportFile(this.payConfirm.ids);
      }
    },
    exportFile(ids) {
      var check_state = this.check_state;
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_list_excelout_by_shipper",
          ...this.form,
          check_state,
          ids,
        },
        callback: (data) => {
          const _data = [
            // 代表 导出 列标题
            [
              "内部运单编号",
              "外部运单编号",
              "外部账单编号",
              " 运输状态",
              "审核状态",
              "总运费",
              "现金运费",
              "燃油费",
              "审核中现金运费",
              "已支付现金运费",
              "结算中金额",
              "已结算金额",
              "开票中金额",
              "已开票金额",
              "发车地址",
              "发车时间",
              "到达地址",
              "到达时间",
              "车牌号码",
              "司机姓名",
              "司机身份证号码",
            ],
          ];
          data.list.forEach((item) => {
            _data.push(Object.values(item));
          });
          const ws = xlsx.utils.aoa_to_sheet(_data);
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
          xlsx.writeFile(wb, "运单列表.xlsx");
        },
      });
    },
    // 勾选数据
    select_table(data) {
      let ids = [];
      let group_nums = [];
      let ra_nums = [];
      var _data = [];
      for (var i in data) {
        ids.push(data[i].id);
        group_nums.push(data[i].group_num);
        ra_nums.push(data[i].ra_num);
        _data.push(data[i].truck_tord_num);
        // this.change_data = _data;
      }
      this.setConfirm.ids = ids;
      this.setConfirm.ra_nums = ra_nums;
      this.payConfirm.ids = ids;
      this.payConfirm.ra_nums = ra_nums;
      this.group_nums = group_nums;
    },
    //tap切换读取数据
    // handleClick() {
    //   this.page.p =1
    //   this.get_page_data();
    // },
    //凭证相关操作
    voucher_show(truck_tord_num) {
      //显示凭证
      this.voucher.is_show++;
      this.voucher.truck_tord_num = truck_tord_num;
    },

    //单笔支付
    one_tord_pay(item) {
      (this.pay.tord_info = item), this.pay.is_show++;
    },
    //清空查询条件
    ser_para_clear() {
      this.form = {
        truck_cargo_num: "", //货源编号
        out_trade_num: "", //上游编号
        truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        cargo_type: "", //货物性质
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        pay_status: "", //支付状态
        invoice_status: "", //开票状态
        settle_status: "", //结算状态

        creat_time_start: "", // 开始时间
        creat_time_end: "", // 结束

        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
      };
      this.get_page_data();
    },

    //发货地省市县选择器
    ser_para_case_cities_change(event) {
      this.form.case_prov = CodeToText[event[0]];
      this.form.case_city = CodeToText[event[1]];
      this.form.case_county = CodeToText[event[2]];
    },

    //到货地省市县选择器
    ser_para_aim_cities_change(event) {
      this.form.aim_prov = CodeToText[event[0]];
      this.form.aim_city = CodeToText[event[1]];
      this.form.aim_county = CodeToText[event[2]];
    },

    //搜索
    cargo_ser() {
      this.form.start_time_start = this.date_value[0] / 1000;
      this.form.arrived_time_end = this.date_value[1] / 1000;
      this.get_page_data();
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;

      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },

    //获取货源列表
    get_page_data() {
      //加载中...
      // if (this.list.loading) {
      //   return;
      // }
      //置为加载中
      this.list.loading = true;

      //读取数据
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_invoice_apply_list_admin_new",
          // status_arr:[1,2],//状态(1:待发车,2:运输中,3:已到货)
          ...this.form,
          ...this.page,
          trans_status: "",
        },
        callback: (data) => {
          console.log(data, 222);
          //加载完毕
          this.list.loading = false;

          //总数
          this.list.total = data.max;

          //预处理
          for (let item of data.list) {
            //成交时间
            item.creat_time_text = this.$my.other.totime(item.creat_time);

            //发车时间
            if (item.start_time > 0) {
              //已发车
              item.start_time_text = this.$my.other.totime(item.start_time);
            } else item.start_time_text = "未发车";

            //到货时间
            if (item.arrived_time > 0) {
              //已到货
              item.arrived_time_text = this.$my.other.totime(item.arrived_time);
            } else item.arrived_time_text = "未到货";

            //装货时间
            if (item.case_date_start == 0 && item.case_date_end != 0) {
              //只有止点

              item.case_date =
                this.$my.other.totime(item.case_date_end) + "以前";
            } else if (item.case_date_start != 0 && item.case_date_end == 0) {
              //只有起点

              item.case_date =
                this.$my.other.totime(item.case_date_start) + "以后";
            } else if (item.case_date_start != 0 && item.case_date_end != 0) {
              //都有

              item.case_date =
                this.$my.other.totime(item.case_date_start) +
                " ~ " +
                this.$my.other.totime(item.case_date_end);
            } else if (item.case_date_start == 0 && item.case_date_end == 0) {
              //都没有

              item.case_date = "未设置";
            }

            //卸货时间
            if (item.aim_date_start == 0 && item.aim_date_end != 0) {
              //只有止点

              item.aim_date = this.$my.other.totime(item.aim_date_end) + "以前";
            } else if (item.aim_date_start != 0 && item.aim_date_end == 0) {
              //只有起点

              item.aim_date =
                this.$my.other.totime(item.aim_date_start) + "以后";
            } else if (item.aim_date_start != 0 && item.aim_date_end != 0) {
              //都有

              item.aim_date =
                this.$my.other.totime(item.aim_date_start) +
                " ~ " +
                this.$my.other.totime(item.aim_date_end);
            } else if (item.aim_date_start == 0 && item.aim_date_end == 0) {
              //都没有

              item.aim_date = "未设置";
            }

            //运单状态
            switch (item.status) {
              case "1":
                item.status_text = "待发车";
                break;
              case "2":
                item.status_text = "运输中";
                break;
              case "3":
                item.status_text = "已到货";
                break;
            }

            //开票状态
            switch (item.invoice_status) {
              case "1":
                item.invoice_status_text = "未申请";
                break;
              case "2":
                item.invoice_status_text = "已申请";
                break;
              case "3":
                item.invoice_status_text = "已开出";
                break;
              case "4":
                item.invoice_status_text = "被驳回";
                break;
            }

            //运费支付状态
            switch (item.freight_payed_status) {
              case "1":
                item.freight_payed_status_text = "未支付";
                break;
              case "2":
                item.freight_payed_status_text = "支付部分";
                break;
              case "3":
                item.freight_payed_status_text = "支付完毕";
                break;
            }

            //手续费支付状态
            switch (item.service_charge_payed_status) {
              case "1":
                item.service_charge_payed_status_text = "未支付";
                break;
              case "2":
                item.service_charge_payed_status_text = "支付部分";
                break;
              case "3":
                item.service_charge_payed_status_text = "支付完毕";
                break;
            }
          }
          var exportFile_ids = [];
          for (var i in data.list) {
            exportFile_ids.push(data.list[i].id);
          }
          this.exportFile_ids = exportFile_ids;
          //渲染
          this.list.data = data.list;
          this.list.item = data.total;
        },
      });
    },

    // //获取可用搜索参数
    // get_ser_para() {
    //   //尝试从缓存中取出
    //   let ser_para = this.$my.cache.get("cargo_creat_page_creat_para");

    //   //如果取出成功
    //   if (ser_para) {
    //     this.ser_para.cargo_type = [...ser_para.cargo_type];
    //     this.ser_para.truck_type = [...ser_para.truck_type];
    //     return;
    //   }

    //   //如果取出失败,去服务器读取
    //   this.$my.net.req({
    //     data: {
    //       mod: "truck_tord_real_time",
    //       ctr: "cargo_creat_para",
    //     },
    //     callback: (data) => {
    //       //格式化车型数据
    //       let truck_type = truck_type_handle.for_el_cascader(data.truck_type);

    //       //缓存之
    //       this.$my.cache.set({
    //         cargo_creat_page_creat_para: {
    //           truck_type: truck_type,
    //           cargo_type: data.cargo_type,
    //         },
    //       });

    //       //置入
    //       this.ser_para.cargo_type = [...data.cargo_type];
    //       this.ser_para.truck_type = [...truck_type];
    //     },
    //   });
    // },

    //确定支付
    to_pay(obj) {
      this.$my.other.confirm({
        content: "点击确定发起支付",
        confirm: () => {
          //关闭弹出层
          this.pay.is_show = 0;

          //提交支付表单
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "truck_tord_pay_by_shipper",
              tord_status: 1, //当前运单状态(1:运输中,2:已到货)
              pay_pwd: obj.pay_pwd,
              pay_list: obj.pay_list,
            },
            callback: (data) => {
              //刷新
              this.get_page_data();
            },
          });
        },
      });
    },

    //显示合同
    show_contract(item) {
      (this.contract.tord_info = item), this.contract.is_show++;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  // height: calc(100% - 134px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin: 0;
}
// #button {
//   margin-left: 140px;
// }
.tabs {
  width: 600px;
}
.tabs_invoice {
  padding-right: 40px;
}
.oli_line_item {
  margin-left: 15px;
  margin-top: 5px;
  font-size: 10px;
  .flex {
    width: 120%;
    display: flex;
    color: #e6a23c;
    justify-content: space-between;
    transform: scale(0.8, 0.8);
    margin-left: -10%;
    div {
      margin: -5px 0px;
      width: 100%;
    }
  }
}
#button {
  margin-left: -50px;
}
.title {
  overflow: visible;
  width: 100%;
  height: 100%;
  word-break: break-all;
  position: static;
  min-width: 500px;
  display: flex;
  font-size: 12px;
  div {
    margin-left: 18px;
  }
}
.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 1px;
  color: #606266;
  span {
    margin-left: 10px;
  }
}
#top {
  font-size: 20px;
}
</style>